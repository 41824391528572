import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react"
import { Box, Flex, Link, Text } from "theme-ui"
import { ProjectCategory, useSiteMetadata } from "gatsby-theme-guix-core"
import Icon from "./Icon"
import { useLocalization } from "gatsby-theme-i18n"
import GatbsyLink from "./Link"
import { useHover } from "@use-gesture/react"
import { useLibsContext } from "../WrapRoot"

interface OwnProps {
  categories: ProjectCategory[]
}

type Props = OwnProps

const Footer: FunctionComponent<Props> = ({ categories }) => {
  const { author } = useSiteMetadata()
  const { anime } = useLibsContext()
  const locales = useLocalization()
  const [target, setTarget] = useState<HTMLElement | null>(null)

  useEffect(() => {
    if (!anime || !target) return

    const icon = target.querySelector("[data-icon]")

    const enter = anime({
      targets: icon,
      translateX: target.clientWidth / 2 - 45,
      translateY: ["-50%", "-50%"],
      height: 90,
      width: 90,
      easing: "easeOutCubic",
      duration: 320,
    })

    return () => {
      anime({
        targets: icon,
        translateX: 0,
        translateY: ["-50%", "-50%"],
        height: 7,
        width: 7,
        easing: "easeOutCubic",
        duration: 320,
        delay: enter.completed ? 100 : 0,
      })
    }
  }, [anime, target])

  const onSocialClick = useCallback((name: string) => {
    // @ts-ignore
    if (typeof window !== "undefined" && window.ga) {
      // @ts-ignore
      window.ga("social-" + name, "click", {})
    }
  }, [])

  const onMouseEnter = useCallback(
    (target: HTMLElement, hovering: boolean | undefined) => {
      setTarget(hovering ? target : null)
    },
    [setTarget]
  )

  const socials = [
    {
      name: "twitter",
      link: author.twitter,
      color: `#${categories[0].color}`,
    },
    {
      name: "gitlab",
      link: author.github,
      color: `#${categories[1].color}`,
    },
    {
      name: "linkedin",
      link: author.linkedin,
      color: `#${categories[2].color}`,
    },
    {
      name: "malt",
      link: author.malt,
      color: `#${categories[0].color}`,
    },
  ].map(s => ({
    ...s,
    bind: useHover(({ target, hovering }) =>
      onMouseEnter(target as HTMLElement, hovering)
    ),
  }))

  return (
    <Flex
      as="nav"
      sx={{
        alignItems: "center",
        flexDirection: ["column", "row"],
        justifyContent: "space-between",
      }}
    >
      {socials.map(l => (
        <Link
          key={l.name}
          href={l.link}
          variant="links.nav"
          target="_blank"
          onClick={() => onSocialClick(l.name)}
          sx={{
            position: "relative",
          }}
          {...l.bind(l)}
        >
          <Icon
            icon={"dot"}
            variant="images.icon-small-color"
            data-icon
            sx={{
              position: "absolute",
              top: "50%",
              left: 0,
              height: "7px",
              transform: "translateY(-50%)",
              fill: l.color,
              stroke: l.color,
              zIndex: -1,
            }}
          />
          <Text as="span" data-label sx={{ display: "inline-block" }}>
            {l.name}
          </Text>
        </Link>
      ))}
      <Box
        sx={{
          flexBasis: "36%",
          textAlign: "right",
        }}
      >
        {locales.config.map(({ code }: { code: string }, index: number) => [
          index > 0 ? (
            <Box as="span" key={`span-${code}`} variant="text.link">
              {"/"}
            </Box>
          ) : (
            ""
          ),
          <GatbsyLink
            variant="links.locale"
            key={code}
            to={"/"}
            language={code}
          >
            <span>{code}</span>
          </GatbsyLink>,
        ])}
      </Box>
    </Flex>
  )
}

export default Footer
