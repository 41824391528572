import React, { FunctionComponent } from "react"
import { PageProps } from "gatsby"
import { HomePageQueryProps, SEO } from "gatsby-theme-guix-core"
import { HomeContact, HomeJumbotron, HomeProjects } from "../../components/Home"
import { Container } from "theme-ui"
import Nav from "../../components/Nav"
import Footer from "../../components/Footer"

type HomePageProps = PageProps<HomePageQueryProps>

const HomePage: FunctionComponent<HomePageProps> = props => {
  const content = props.data.contentfulHomePage

  return (
    <>
      <SEO contentfulPage={content} />
      <Container as="header" variant="header">
        <Nav menu={content.menu} />
      </Container>
      <Container as="main">
        <HomeJumbotron {...content} />
        <HomeProjects homepage={content} />
        <HomeContact homepage={content} />
      </Container>
      <Container as="footer" variant="footer">
        <Footer categories={content.categories} />
      </Container>
    </>
  )
}

export default HomePage
