import React, { FunctionComponent, useRef } from "react"
import { AspectRatio, Box, Image } from "theme-ui"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
// import { useLibsContext } from "../../WrapRoot"
// import { useCursor } from "../Cursor"

interface OwnProps {}

type Props = OwnProps

const HomeContactAuthor: FunctionComponent<Props> = props => {
  const radius = [37, 37, 52, 65]

  const container = useRef<HTMLDivElement>(null!)

  const pictures = useStaticQuery(graphql`
    query ContactPictureQuery {
      foreground: file(name: { eq: "fg-contact" }) {
        childImageSharp {
          gatsbyImageData(width: 320, formats: PNG)
        }
      }
      background: file(name: { eq: "bg-contact" }) {
        childImageSharp {
          gatsbyImageData(width: 320)
        }
      }
    }
  `)

  return (
    <Box
      ref={container}
      sx={{
        position: "relative",
        overflow: "hidden",
        width: "100%",
        borderBottomLeftRadius: radius,
        borderBottomRightRadius: radius,
        transformOrigin: "center center",
        pt: 50,
        ".author-fg": {
          display: "block",
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          zIndex: 2,
          borderBottomLeftRadius: radius,
          borderBottomRightRadius: radius,
        },
        ".author-bg": {
          display: "block",
          position: "absolute",
          top: "50px",
          left: 0,
          bottom: 0,
          right: 0,
          overflow: "hidden",
          borderTopLeftRadius: radius,
          borderTopRightRadius: radius,
          borderBottomLeftRadius: radius,
          borderBottomRightRadius: radius,
          zIndex: 1,
        },
      }}
    >
      <AspectRatio ratio={3 / 4} />
      <GatsbyImage
        image={pictures.background.childImageSharp.gatsbyImageData}
        alt="background portrait"
        className="author-bg"
      />
      <GatsbyImage
        image={pictures.foreground.childImageSharp.gatsbyImageData}
        alt="portrait"
        className="author-fg"
      />
    </Box>
  )
}

export default HomeContactAuthor
