import React, { FunctionComponent, useEffect, useRef } from "react"
import { Flex, NavLink } from "theme-ui"
import { useSiteMetadata } from "gatsby-theme-guix-core"
import { useLibsContext } from "../WrapRoot"
import { usePageContext } from "../WrapPage"

interface OwnProps {
  menu: string[]
}

type Props = OwnProps

const Nav: FunctionComponent<Props> = ({ menu }) => {
  const { author } = useSiteMetadata()
  const { anime } = useLibsContext()
  const { ready } = usePageContext()
  const animation = useRef<any>(null)
  const container = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (anime && container.current && !ready) {
      animation.current = anime({
        autoplay: false,
        targets: container.current.children,
        translateY: ["-20%", 0],
        opacity: [0, 1],
        duration: 800,
        easing: "easeOutCubic",
      })
    }
  }, [anime, ready])

  useEffect(() => {
    if (animation.current && ready) {
      animation.current.play()
    }
  }, [ready])

  return (
    <Flex
      ref={container}
      as="nav"
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: ["space-between", "space-between", "flex-start"],
      }}
    >
      <NavLink
        href="/"
        variant="menu"
        sx={{
          pl: ["10px", "20px"],
        }}
      >
        <span>
          {author.firstName} {author.lastName}
        </span>
      </NavLink>
      <NavLink
        href="#contact"
        variant="menu"
        sx={{
          pr: ["10px", "20px"],
        }}
      >
        <span>{menu[0]}</span>
      </NavLink>
    </Flex>
  )
}

export default Nav
