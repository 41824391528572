import React, { FunctionComponent, useEffect, useRef, useState } from "react"
import {
  Box,
  Flex,
  Heading,
  Link,
  Text,
  AspectRatio,
  LinkProps,
} from "theme-ui"
import { HomePage, ProjectCategory } from "gatsby-theme-guix-core"
import { useLibsContext } from "../../WrapRoot"
import Icon from "../Icon"
import { default as HomeJumbotronText } from "../Utils/SentenceToLetter"
import { usePageContext } from "../../WrapPage"
import { GatsbyImage } from "gatsby-plugin-image"
import { useCursor } from "../Cursor"

const HomeJumbotronLink: FunctionComponent<
  LinkProps & {
    project: ProjectCategory
  }
> = ({ project, ...props }) => (
  <Link
    {...props}
    as="span"
    sx={{
      ".label": { display: "inline-block" },
      ...props.sx,
    }}
    variant="highlight"
    className={`link ${project.className} ${props.className || ""}`}
  >
    <Icon
      className="icon category-icon"
      svg={project.icon.svg?.content}
      sx={{ height: "1.125em" }}
    />{" "}
    <HomeJumbotronText className="label" text={project.name} />
  </Link>
)

const HomeJumbotron: FunctionComponent<HomePage> = props => {
  const { ready } = usePageContext()
  const { anime } = useLibsContext()
  const { position } = useCursor()

  const jumbotronAnim = useRef<any>(null)
  const mouseAnim = useRef<number>(Date.now())

  const [entered, setEntered] = useState(ready)
  const [selected, setSelected] = useState<ProjectCategory | undefined>(
    ready ? props.categories[2] : undefined
  )

  // Mouse anim
  useEffect(() => {
    if (!anime || !selected) return

    if (mouseAnim.current + 40 > Date.now()) return

    mouseAnim.current = Date.now()

    let offset = selected.className === "project3D" ? 8 : 16

    anime({
      targets: `.category-image.${selected.className} .translate-image`,
      translateX: (el: any, i: number) => {
        let dir = i === 0 || i === 2 ? -1 : 1
        return dir * (0.5 - position[1] / window.innerWidth) * (offset * 3)
      },
      translateY: (el: any, i: number) => {
        let dir = i === 1 || i === 2 ? -1 : 1
        return dir * (0.5 - position[0] / window.innerHeight) * offset
      },
      easing: "easeOutCirc",
      duration: 1200,
    })
  }, [anime, entered, position, selected])

  // Category anim
  useEffect(() => {
    if (!anime) return

    anime({
      targets: `.category-image`,
      scale: 0,
      opacity: 0,
      duration: 0,
    })
  }, [anime])

  useEffect(() => {
    if (!anime || selected === undefined) return

    const enterAnim = anime
      .timeline()
      .add({
        targets: `.link.${selected.className} .letter`,
        color: ["#fff", `#${selected.color}`],
        translateY: [0, "3px", "-1px", 0],
        easing: "easeInOutCubic",
        duration: 420,
        delay: (el: any, i: number) => 40 * i,
      })
      .add(
        {
          targets: `.category-image.${selected.className}`,
          scale: 1,
          opacity: 1,
          transformOrigin: "center center",
          easing: "easeInOutCubic",
          duration: 720,
        },
        "-=600"
      )

    return () => {
      enterAnim.pause()
      anime
        .timeline()
        .add({
          targets: `.link.${selected.className} .letter`,
          color: "#fff",
          translateY: 0,
          easing: "easeInOutCubic",
          duration: 420,
        })
        .add(
          {
            targets: `.category-image.${selected.className}`,
            scale: 0,
            opacity: 0,
            transformOrigin: "center center",
            easing: "easeInOutCubic",
            duration: 720,
          },
          "-=600"
        )
    }
  }, [anime, selected])

  // Intro anim
  useEffect(() => {
    if (!anime || entered) return

    const letterAnimation = {
      scale: [0, 1],
      opacity: [0.2, 1],
      transformOrigin: "bottom center",
      easing: "easeOutElastic",
      //duration: 1200,
      duration: 1550,
      delay: (el: any, i: number) => 30 * i,
    }

    const categoryIconAnimation = {
      scale: [0, 1],
      transformOrigin: "center center",
      easing: "easeOutExpo",
      duration: 340,
    }

    const categoryLabelAnimation = {
      translateY: ["30%", "0%"],
      opacity: [0, 1],
      transformOrigin: "bottom center",
      easing: "easeOutExpo",
      duration: 340,
    }

    const explainAnimation = {
      translateY: ["-30%", "0%"],
      opacity: [0, 1],
      transformOrigin: "bottom center",
      easing: "easeOutExpo",
      duration: 340,
    }

    const letterOffset = "-=1200"
    const linkOffset = "-=1000"

    jumbotronAnim.current = anime
      .timeline({
        autoplay: ready,
      })
      .add({ targets: ".intro0 .letter", ...letterAnimation })
      .add({ targets: ".intro1 .letter", ...letterAnimation }, letterOffset)
      .add(
        {
          targets: ".intro2 .letter",
          ...letterAnimation,
        },
        letterOffset
      )
      .add(
        {
          targets: `.${props.categories[0].className}.link .icon`,
          ...categoryIconAnimation,
          begin: () => setSelected(props.categories[0]),
        },
        letterOffset
      )
      .add(
        {
          targets: `.${props.categories[0].className}.link .label`,
          ...categoryLabelAnimation,
        },
        linkOffset
      )
      .add(
        {
          targets: `.${props.categories[1].className}.link .icon`,
          ...categoryIconAnimation,
          begin: () => setSelected(props.categories[1]),
        },
        "+=100"
      )
      .add(
        {
          targets: `.${props.categories[1].className}.link .label`,
          ...categoryLabelAnimation,
        },
        "-=300"
      )
      .add({ targets: ".intro3 .letter", ...letterAnimation }, "+=600")
      .add(
        {
          targets: `.${props.categories[2].className}.link .icon`,
          ...categoryIconAnimation,
          begin: () => setSelected(props.categories[2]),
        },
        letterOffset
      )
      .add(
        {
          targets: `.${props.categories[2].className}.link .label`,
          ...categoryLabelAnimation,
        },
        linkOffset
      )
      .add({ targets: ".explain", ...explainAnimation }, "+=600")
      .add({
        targets: ".explain-arrow",
        ...explainAnimation,
        complete: () => setEntered(true),
      })
  }, [anime, entered, ready])

  return (
    <Flex
      as="section"
      sx={{
        position: "relative",

        rowGap: 2,
        columnGap: 2,
        flexWrap: "wrap",
        alignItems: "center",
        alignContent: "center",
        justifyContent: ["center", "center", "space-between"],
        flexDirection: ["column", "column", "row"],

        minHeight: "100vh",
        py: [3, 5, 0],
      }}
    >
      <Heading variant="jumbotron" as="h1">
        <HomeJumbotronText className={"intro0"} text={props.intro[0]} /> <br />
        <HomeJumbotronText className={"intro1"} text={props.intro[1]} /> <br />
        <HomeJumbotronText className={"intro2"} text={props.intro[2]} />{" "}
        <HomeJumbotronLink
          onClick={entered ? () => setSelected(props.categories[0]) : undefined}
          project={props.categories[0]}
        />{" "}
        <HomeJumbotronLink
          onClick={entered ? () => setSelected(props.categories[1]) : undefined}
          project={props.categories[1]}
        />{" "}
        <br />
        <HomeJumbotronText className={"intro3"} text={props.intro[3]} />{" "}
        <HomeJumbotronLink
          onClick={entered ? () => setSelected(props.categories[2]) : undefined}
          project={props.categories[2]}
        />
      </Heading>
      <Box
        as="aside"
        sx={{
          position: "relative",
          flexGrow: [0, 0, 1],
          width: ["220px", "300px", "auto"],
          maxWidth: "480px",
          ".category-image": {
            position: "absolute",
          },
          ".webdesign": {
            width: "76%",
            bottom: "2%",
            right: "-14%",
            "& + .webdesign": {
              top: "0",
              left: "-14%",
              bottom: "auto",
              right: "auto",
            },
          },
          ".project3D": {
            width: "110%",
            top: "5%",
            left: "-5%",
          },
          ".videogame": {
            width: "82%",
            bottom: "-5%",
            left: "-16%",
            "& + .videogame": {
              top: "5%",
              right: "-10%",
              bottom: "auto",
              left: "auto",
            },
          },
        }}
      >
        {props.categories.map(category =>
          category.images?.map(i => (
            <Box className={`category-image ${category.className}`} key={i.url}>
              <Box className={`translate-image`}>
                <GatsbyImage
                  image={i.gatsbyImageData}
                  loading="eager"
                  alt={i.alt || ""}
                />
              </Box>
            </Box>
          ))
        )}
        <AspectRatio sx={{ width: "100%" }} ratio={3 / 4} as="div" />
      </Box>
      <Flex
        className={"explain"}
        variant="explain"
        sx={{
          display: ["none", "flex"],
          alignSelf: "flex-end",
          justifyContent: "flex-end",
          alignItems: "center",
          columnGap: "1em",
          flexBasis: "100%",
          textAlign: "right",
        }}
        as="p"
      >
        {props.menu[1]}
        <Flex
          as="span"
          className={"explain-arrow"}
          sx={{
            flexDirection: "column",
            rowGap: "1px",
            pt: "1px",
          }}
        >
          {[1, 2, 3, 4, 5].map((v, i) => (
            <Icon
              key={v}
              icon={"arrow"}
              variant={"images.explain"}
              className={entered ? "active" : ""}
              sx={{
                animationDelay: `${120 * i}ms`,
              }}
            />
          ))}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default HomeJumbotron
