import React, {
  createRef,
  FunctionComponent,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"
import { ProjectPage } from "gatsby-theme-guix-core"
import ProjectItem from "./ProjectItem"
import { Flex } from "theme-ui"

interface OwnProps {
  projectList: ProjectPage[]
}

type Props = OwnProps

const ProjectList: FunctionComponent<Props> = ({ projectList }) => {
  const observerRef = useRef<IntersectionObserver>()
  const projectRefs = useRef(
    projectList.map(() =>
      createRef<HTMLDivElement>()
    ) as RefObject<HTMLDivElement>[]
  )

  const [projectActive, setProjectActive] = useState<
    IntersectionObserverEntry[]
  >([])

  useEffect(() => {
    const options = {
      rootMargin: "0%",
      threshold: [0.45, 1],
    }

    const callback = (
      entries: IntersectionObserverEntry[],
      observer: IntersectionObserver
    ) => {
      setProjectActive(entries.filter(e => e.isIntersecting))
    }

    observerRef.current = new IntersectionObserver(callback, options)
  }, [setProjectActive])

  useEffect(() => {
    projectRefs.current.map(
      ref => ref.current && observerRef.current?.observe(ref.current)
    )

    return () => {
      projectRefs.current.map(
        ref => ref.current && observerRef.current?.unobserve(ref.current)
      )
    }
  }, [])

  const isActive = useCallback(
    (el: Element) => !!projectActive.find(p => p.target === el),
    [projectActive]
  )

  return (
    <Flex
      as="section"
      sx={{
        flexDirection: "column",
        alignItems: ["center", "center", "flex-start"],
        py: [6, 4],
        px: [0, 0, 0, 5],
        rowGap: [8, 4, 4, 7],
        overflowX: "hidden",
        "> *:nth-of-type(even)": {
          alignSelf: ["center", "center", "flex-end"],
        },
      }}
    >
      {projectList.map((p, i) => (
        <ProjectItem
          ref={projectRefs.current[i]}
          key={p.slug + i}
          active={isActive(projectRefs.current[i].current as Element)}
          {...p}
        />
      ))}
    </Flex>
  )
}

export default ProjectList
