import React, { FunctionComponent } from "react"
import { Box, BoxProps } from "theme-ui"
import { graphql, useStaticQuery } from "gatsby"

interface OwnProps {
  svg?: string
  icon?: string
}

type Props = OwnProps & BoxProps

const Icon: FunctionComponent<Props> = ({ svg, icon, ...props }) => {
  const icons = useStaticQuery(graphql`
    query IconsQuery {
      allFile(
        filter: {
          sourceInstanceName: { eq: "images" }
          extension: { eq: "svg" }
        }
      ) {
        nodes {
          svg {
            content
          }
          name
        }
      }
    }
  `)

  const selected = icons.allFile.nodes.find((n: any) => n.name === icon)

  return (
    <Box
      variant="images.icon"
      as={"span"}
      {...props}
      className={`icon ${props.className || ""}`}
      dangerouslySetInnerHTML={{
        __html: svg ? svg : selected?.svg.content,
      }}
    />
  )
}

export default Icon
