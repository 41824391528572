import React, { FunctionComponent } from "react"
import { HomePage } from "gatsby-theme-guix-core"
import ProjectList from "../Project/ProjectList"

interface OwnProps {
  homepage: HomePage
}

type Props = OwnProps

const HomeProjects: FunctionComponent<Props> = ({ homepage }) => {
  return <ProjectList projectList={homepage.projects} />
}

export default HomeProjects
