import React, { FunctionComponent, useCallback, useRef } from "react"
import { Box, Heading, Link, Text } from "theme-ui"
import { HomePage, useSiteMetadata } from "gatsby-theme-guix-core"
import Icon from "../Icon"
import HomeContactAuthor from "./HomeContactAuthor"
import { useHover } from "@use-gesture/react"
import { useLibsContext } from "../../WrapRoot"

interface OwnProps {
  homepage: HomePage
}

type Props = OwnProps

const HomeContact: FunctionComponent<Props> = ({ homepage }) => {
  const { author } = useSiteMetadata()
  const { anime } = useLibsContext()
  const contactRef = useRef<HTMLAnchorElement>(null!)
  const animRef = useRef<any>(null)

  const playAnimation = useCallback(() => {
    if (anime && (!animRef.current || animRef.current.completed)) {
      const icon = contactRef.current.querySelector("[data-icon]")

      animRef.current = anime
        .timeline()
        .add({
          targets: icon,
          translateX: 15,
          translateY: -15,
          opacity: [1, 0],
          scale: [1, 0.7],
          // transformOrigin: "top right",
          easing: "easeOutCirc",
          duration: 280,
        })
        .add({
          targets: icon,
          translateX: [-15, 0],
          translateY: [15, 0],
          opacity: [0, 1],
          scale: [0.7, 1],
          // transformOrigin: "top right",
          easing: "easeOutCirc",
          duration: 280,
        })
    }
  }, [anime])

  useHover(
    state => {
      if (state.hovering) playAnimation()
    },
    {
      target: contactRef,
    }
  )

  return (
    <Box
      id="contact"
      as="section"
      sx={{
        position: "relative",
        mt: [5, 5, 4],
        mb: [3, 3, 5],
        pr: [1, 0],
        pl: [2, 0],
        "> p, > h2": {
          mx: ["auto", 0],
          maxWidth: ["350px", "460px", "58%", "750px"],
        },
      }}
    >
      <Box
        sx={{
          position: ["relative", "absolute", "absolute"],
          // top: "50%",
          top: [0, "-30px", "-50px"],
          right: 0,
          // transform: ["", "translateY(-50%)"],
          width: ["200px", "230px", "280px", "320px"],
          mb: [3, 3, 0],
          mx: ["auto", 0],
        }}
      >
        <HomeContactAuthor />
      </Box>
      <Heading
        variant={"h1"}
        sx={{
          pb: [1, 1, 2],
        }}
      >
        {homepage.menu[2]}
      </Heading>
      <Text variant="contact" as="p">
        {homepage.contact[0]}{" "}
        <Link
          href={author.github}
          target="_blank"
          variant="highlight"
          sx={{
            color: `#${homepage.categories[1].color}`,
          }}
        >
          {homepage.contact[1]}
        </Link>{" "}
        {homepage.contact[2]}
      </Text>
      <Text variant="contact" as="p">
        {homepage.contact[3]}{" "}
        <Link
          href={author.linkedin}
          variant="highlight"
          target="_blank"
          sx={{
            color: `#${homepage.categories[2].color}`,
          }}
        >
          {homepage.contact[4]}
        </Link>
      </Text>
      <Text variant="contact" as="p">
        {homepage.contact[5]}{" "}
        <Link
          ref={contactRef}
          variant="highlight"
          target="_blank"
          href={`mailto:${author.email}`}
          onClick={playAnimation}
          sx={{
            color: `#${homepage.categories[0].color}`,
          }}
        >
          {homepage.contact[6]} <Icon data-icon icon={"contact"} />
        </Link>{" "}
        {homepage.contact[7]}
      </Text>
    </Box>
  )
}

export default HomeContact
