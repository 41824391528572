/** @jsx jsx */
import React from "react"

import type { GatsbyLinkProps } from "gatsby"
import { LocalizedLink as GatsbyLink } from "gatsby-theme-i18n"
import type { FC } from "react"
import { forwardRef } from "react"
import type { LinkProps as ThemeLinkProps } from "theme-ui"
import { jsx, Link as ThemeLink } from "theme-ui"

type LinkProps = Omit<ThemeLinkProps, "as"> & AsLinkProps
type AsLinkProps = Omit<GatsbyLinkProps<unknown>, "ref" | "activeClassName"> & {
  language: string
}

const Link = forwardRef<HTMLAnchorElement, LinkProps>(function Link(
  props,
  ref
) {
  return (
    <ThemeLink
      ref={ref}
      variant="default"
      {...props}
      as={props.href ? "a" : AsLink}
    />
  )
})

const AsLink: FC<AsLinkProps> = props => (
  <GatsbyLink activeClassName="active" {...props} />
)

export default Link
