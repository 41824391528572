import React from "react"
import { graphql } from "gatsby"
import HomePageComponent from "../components/HomePage"
import { HomePage } from "../types"

export interface HomePageQueryProps {
  contentfulHomePage: HomePage
}

export default HomePageComponent

export const query = graphql`
  query HomePageQuery($hrefLang: String!) {
    contentfulHomePage(node_locale: { eq: $hrefLang }) {
      ...homepageFragment
    }
  }
`
