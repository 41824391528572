import React, {
  forwardRef,
  ForwardRefRenderFunction,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { withPrefix } from "gatsby"
import { AspectRatio, Box, Card, Flex, Heading, Link, Themed } from "theme-ui"
import { GatsbyImage, getSrc } from "gatsby-plugin-image"
import { ProjectPage } from "gatsby-theme-guix-core"
import Icon from "../Icon"
import SentenceToLetter from "../Utils/SentenceToLetter"
import { useCursorHover } from "../Cursor"
import { useLibsContext } from "../../WrapRoot"
import { usePageContext } from "../../WrapPage"

interface OwnProps {
  active: boolean
}

type Props = ProjectPage & OwnProps

const ProjectItem: ForwardRefRenderFunction<HTMLDivElement, Props> = (
  props,
  ref
) => {
  const { anime } = useLibsContext()
  const { ready } = usePageContext()

  const animation = useRef<any>(undefined)
  const videoRef = useRef<HTMLVideoElement>(null!)

  const [posterReady, setPosterReady] = useState(false)
  const [videoReady, setVideoReady] = useState(false)
  const [entered, setEntered] = useState(false)
  const activated = useMemo(() => props.active, [props.active])
  const [playing, setPlaying] = useState(false)
  const [hoverRef] = useCursorHover<HTMLAnchorElement>({
    color: `#${props.category.color}`,
  })

  const onProjectClick = useCallback((name: string) => {
    // @ts-ignore
    if (typeof window !== "undefined" && window.ga) {
      // @ts-ignore
      window.ga("project-" + name, "click", {})
    }
  }, [])

  const onProjectView = useCallback((name: string) => {
    // @ts-ignore
    if (typeof window !== "undefined" && window.ga) {
      // @ts-ignore
      window.ga("project-" + name, "open", {})
    }
  }, [])

  useEffect(() => {
    if (!anime) return

    const contentAnimation = {
      opacity: [0, 1],
      translateY: [80, 0],
      duration: 600,
      easing: "easeOutQuad",
    }

    const categoryAnimation = {
      // scale: [0, 1],
      opacity: [0, 1],
      translateY: [0, "3px", "-1px", 0],
      easing: "easeInOutCubic",
      duration: 420,
      delay: (el: any, i: number) => 30 * i,
    }

    const container = (ref as RefObject<HTMLDivElement>).current

    animation.current = anime
      .timeline({
        autoplay: false,
      })
      .add({
        targets: container?.querySelector("h2"),
        ...contentAnimation,
      })
      .add(
        {
          targets: container?.querySelector("main"),
          ...contentAnimation,
        },
        "-=500"
      )
      .add(
        {
          targets: container?.querySelector("p"),
          ...contentAnimation,
        },
        "-=550"
      )
      .add(
        {
          targets: container?.querySelector(".project-category-icon"),
          ...categoryAnimation,
        },
        "-=500"
      )
      .add(
        {
          targets: container?.querySelectorAll(
            ".project-category-label .letter"
          ),
          ...categoryAnimation,
          complete: () => {
            onProjectView(props.slug)
            setEntered(true)
          },
        },
        "-=500"
      )
  }, [anime, setEntered, onProjectView])

  useEffect(() => {
    if (videoReady && entered && activated) {
      const promise = videoRef.current.play()

      return () => {
        promise.then(() => videoRef.current.pause())
      }
    }
  }, [videoReady, entered, activated])

  useEffect(() => {
    if (posterReady && activated && !entered) {
      animation.current.play()
      videoRef.current.load()
    }
  }, [posterReady, activated, entered])

  return (
    <Card variant="project" as="article" ref={ref}>
      <Flex as="header">
        <Link
          href={props.link || "#"}
          aria-label={props.title}
          target="_blank"
          onClick={() => onProjectClick(props.slug)}
        >
          <Heading as="h2">{props.title}</Heading>
        </Link>
        <Heading
          as="h3"
          sx={{
            color: `#${props.category.color}`,
            fill: `#${props.category.color}`,
          }}
        >
          <Icon
            icon={"dot"}
            className={"project-category-icon"}
            variant={"images.icon-small-color"}
            sx={{ pr: ".8em", display: ["none", "inline-block"] }}
          />
          <SentenceToLetter
            text={props.subtitle}
            className={"project-category-label"}
          />
        </Heading>
      </Flex>
      <Box
        as="main"
        sx={{
          position: "relative",
          overflow: "hidden",
          width: "100%",
        }}
      >
        <Link
          ref={hoverRef}
          href={props.link || "#"}
          aria-label={props.title}
          onClick={() => onProjectClick(props.slug)}
          target="_blank"
        >
          <AspectRatio ratio={16 / 9} />
          <GatsbyImage
            image={props.poster.gatsbyImageData}
            alt={props.title}
            onLoad={() => setPosterReady(true)}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              pointerEvents: "none",
              opacity: playing ? 0 : 1,
              transition: "opacity 80ms ease-out",
              zIndex: 1,
            }}
          />
          <video
            ref={videoRef}
            muted={true}
            controls={false}
            playsInline={true}
            loop={true}
            onPlay={() => setPlaying(true)}
            onCanPlay={() => setVideoReady(true)}
            onCanPlayThrough={() => setVideoReady(true)}
            poster={getSrc(props.poster.gatsbyImageData)}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              display: "block",
            }}
          >
            <source
              src={
                ready
                  ? withPrefix(props.video.webm.path.replace("\\", "/"))
                  : ""
              }
              type="video/webm; codecs=vp9,vorbis"
            />
            <source
              src={
                ready ? withPrefix(props.video.mp4.path.replace("\\", "/")) : ""
              }
              type="video/mp4"
            />
          </video>
        </Link>
      </Box>
      <Themed.p>{props.description.description}</Themed.p>
    </Card>
  )
}

export default forwardRef(ProjectItem)
