import React, { FunctionComponent } from "react"
import { Page } from "../types"
import { GatsbySeo } from "gatsby-plugin-next-seo"
import useSiteMetadata from "../hooks/useSiteMetadata"
import { useLocalization } from "gatsby-theme-i18n"

interface OwnProps {
  contentfulPage: Page
}

type Props = OwnProps

const SEO: FunctionComponent<Props> = props => {
  const { seo } = props.contentfulPage
  const { author, siteUrl, siteName, logoURL } = useSiteMetadata()
  const { locale } = useLocalization()

  return (
    <>
      <GatsbySeo
        title={seo.title}
        description={seo.description}
        noindex={seo.noindex}
        nofollow={seo.nofollow}
        twitter={{
          site: author.twitter,
          cardType: "summary_large_image",
        }}
        openGraph={{
          type: "website",
          site_name: siteName,
          title: seo.title,
          description: seo.description,
          locale: locale,
          url: siteUrl,
          profile: {
            firstName: author.firstName,
            lastName: author.lastName,
            username: author.userName,
          },
          images: seo.images?.map(i => ({
            url: i.url,
            width: 1200,
            height: 630,
            alt: i.alt,
          })),
        }}
      />
      {/*<JsonLd*/}
      {/*  json={{*/}
      {/*    "@context": "https://schema.org",*/}
      {/*    "@type": "WebSite",*/}
      {/*    url: siteUrl,*/}
      {/*    description: seo.description,*/}
      {/*    name: siteName,*/}
      {/*    image: seo.images?.[0].url,*/}
      {/*    abstract: seo.description,*/}
      {/*    creator: {*/}
      {/*      "@context": "https://schema.org",*/}
      {/*      "@type": "Organization",*/}
      {/*      name: siteName,*/}
      {/*      url: siteUrl,*/}
      {/*      logo: logoURL,*/}
      {/*      email: author.email,*/}
      {/*    },*/}
      {/*    copyrightHolder: {*/}
      {/*      "@context": "https://schema.org",*/}
      {/*      "@type": "Organization",*/}
      {/*      name: siteName,*/}
      {/*      url: siteUrl,*/}
      {/*      logo: logoURL,*/}
      {/*      email: author.email,*/}
      {/*    },*/}
      {/*  }}*/}
      {/*/>*/}
    </>
  )
}

export default SEO
