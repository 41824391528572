import React, { FunctionComponent } from "react"
import { graphql, useStaticQuery } from "gatsby"

interface OwnProps {
  siteUrl: string
  siteName: string
  logoURL: string
  author: {
    firstName: string
    lastName: string
    userName: string
    twitter: string
    linkedin: string
    github: string
    malt: string
    email: string
  }
}

type Props = OwnProps

const useSiteMetadata = (): Props => {
  const data = useStaticQuery(graphql`
    query SiteMetadataQuery {
      logo: file(name: { eq: "logo" }) {
        publicURL
      }
      site {
        siteMetadata {
          siteUrl
          siteName
          author {
            firstName
            lastName
            userName
            twitter
            linkedin
            github
            malt
            email
          }
        }
      }
    }
  `)

  return {
    ...data.site.siteMetadata,
    logoURL: data.logo.publicURL,
  }
}

export default useSiteMetadata
