import React, { FunctionComponent, HTMLProps } from "react"
import { Box } from "theme-ui"

interface OwnProps {
  text: string
}

type Props = HTMLProps<HTMLDivElement> & OwnProps

const SentenceToLetter: FunctionComponent<Props> = ({ className, text }) => (
  <>
    {text.split(" ").map((w, wi) => [
      <Box
        key={wi}
        as="span"
        className={`word ${className}`}
        data-word={w}
        sx={{
          whiteSpace: "nowrap",
          display: "inline-block",
          verticalAlign: "middle",
          span: {
            display: "inline-block",
          },
        }}
      >
        {w.split("").map((l, li) => (
          <span key={li} className="letter">
            {l}
          </span>
        ))}
      </Box>,
      " ",
    ])}
  </>
)

export default SentenceToLetter
